<template>
	<div class="space-x-2 mt-2">
		<span v-if="props.moveEvent?.formatted_type" class="text-jmi-purple2 bg-jmi-purple4 rounded py-0.5 px-1 text-xs">{{ props.moveEvent.formatted_type }}</span>
		<span class="text-jmi-purple2 bg-jmi-purple4 rounded py-0.5 px-1 text-xs">
			<span>
				{{ labelDisplay }}
			</span>
		</span>
		<span v-if="props.moveEvent?.type === 'letting' && props.moveEvent?.managed" class="text-jmi-purple2 bg-jmi-purple4 rounded py-0.5 px-1 text-xs">managed</span>
		<slot />
	</div>
</template>
<script setup lang="ts">
import {MoveEvent} from "~/utils/types";
import {computed} from "vue";

const props = defineProps<{
	moveEvent?: MoveEvent
}>();

// Create a computed property that returns the URL for the move event
const labelDisplay = computed(() => {
	if (props.moveEvent?.type === 'letting') {
		return props.moveEvent.movedate_confirmed ? 'confirmed move date' : 'estimated move date';
	} else {
		return props.moveEvent?.movedate_confirmed ? 'exchanged' : 'pre-exchange';
	}
});

</script>
